
// Import any necessary dependencies
import AOS from 'aos';

// This function is called whenever the initial (first) rendering of your site takes place
export const onInitialClientRender = () => {
  // Initialize AOS library for animations on scroll
  AOS.init({
    duration: 1000,
    easing: "ease-in-out",
    once: true,
    mirror: false
  });
};

// This function is called whenever the browser navigates to a new page
export const onRouteUpdate = () => {
  // Reinitialize AOS library after route updates
  AOS.refresh();
};